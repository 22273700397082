$(() => {
  
  $(document).on('click', ($event) => {
    if ($($event.currentTarget).hasClass('disabled')) {
      $event.stopPropagation();
      $event.preventDefault();
    }
  });

  const breakPoints: eh.Breakpoints = eh.Breakpoints.getInstance();
  let bwer = window.browserDetect();
  const isIE11 = bwer && bwer.name === 'ie' && (bwer.version.indexOf('11.') > -1);
  const headerNavigation: JQuery<HTMLElement> = $('.eh-header--navigation');
  const navigationGrid: JQuery<HTMLElement> = $('.eh-main-categories-navigation-links', headerNavigation);

  $('.trigger-toggle-navigation').on('click', ($event) => {
    const siteEl: JQuery<Element> = eh.ScrollPage.getScrollRoot();
    const toggleNaviClassName: string = 'eh-navigation-active';
    const isOpen: boolean = siteEl.hasClass(toggleNaviClassName);

    $event.preventDefault();
    eh.ScrollPage.setScrollEnabled(isOpen);
    siteEl.toggleClass(toggleNaviClassName, !isOpen);

    // workaround to help ie11 to calculate layout on cleared floats.
    // force hasLayout on eh-main-categories-navigation-links
    if (isIE11) {
      if (!isOpen) {
        navigationGrid.css('width', `${window.innerWidth}px`);
        window.requestAnimationFrame(() => {
          navigationGrid.css('width', `${window.innerWidth - 18}px`); // - visible scrollbar 18px
        });
      } else {
        navigationGrid.removeAttr('style');
      }
    }

  });

  if(isIE11) {
    let t: number = 0;

    breakPoints.registerResizeListener((): void => {
      // update mobile navigation flyout
      const siteEl: JQuery<Element> = eh.ScrollPage.getScrollRoot();
      const toggleNaviClassName: string = 'eh-navigation-active';
      const isOpen: boolean = siteEl.hasClass(toggleNaviClassName);
      if (isOpen && breakPoints.isMobile) {
        navigationGrid.css('width', `${window.innerWidth - 18}px`); // - visible scrollbar 18px
      } else {
        navigationGrid.css('width', `auto`);
      }

      // throttle picturefill
      if (t !== 0) {
        return;
      }
      t = window.setTimeout(() => {
        t = 0;
        window.picturefill({
          reevaluate: true,
          elements: $('img', document)
        });
      }, 300);
    });
  }
  
  const $root = $(':root');
  $root.on(cs.Snippet.EventIdPostReplace, ($event: cs.SnippetEventPostReplace) => {
    let target = $event.replacedTarget
    if (!target && $event.originalEvent) {
      const oEvent = ($event.originalEvent as any as cs.SnippetEventPostReplace | null)
      if (oEvent?.replacedTarget) {
        target = oEvent.replacedTarget;
      }
    }
    if (target){
      eh.Start.init($(target), true);
    }
  });
  eh.Start.init($root);
  
});

log.setDefaultLevel(['dev', 'qa'].indexOf($('body').data('csEnv')) !== -1 ? log.levels.WARN : log.levels.SILENT);

namespace eh {
  
  export class Start {
    
    static init($base: JQuery<HTMLElement>, isSnippetRequest = false): void {

      cs.Snippet.init($base);
      eh.StickyManager.init($base, isSnippetRequest);
      eh.Header.init($base, isSnippetRequest);
      eh.NavigationController.init($base);
      eh.PreventRubberbandScroller.init($base);
      eh.CookieDisclaimer.init($base);
      eh.CountryDetector.init($base);
      eh.DatePicker.init($base);
      eh.IframeScale.init($base);
      eh.LanguageChooser.init($base);
      eh.LinkTarget.init($base);
      eh.LoadingIndicator.init();
      eh.MarketChooser.init($base);
      eh.MarketSelector.init($base);
      eh.Overlay.init($base, isSnippetRequest);
      eh.PopoverButton.init($base);
      eh.QuickSelect.init($base, isSnippetRequest);
      eh.ProductPricing.init($base, isSnippetRequest);
      eh.SeamlessIFrame.init($base);
      eh.SearchFiltersLegacy.init($base, isSnippetRequest);
      eh.SiteSearch.init($base, isSnippetRequest);
      eh.Slider.init($base);
      eh.TermHighlights.init($base);
      eh.VideoPlayer.init($base);
      eh.ShortcutController.init($base);
      eh.Tracking.init($base, isSnippetRequest);
      eh.FlexInfo.init($base);

      // finally expose global api
      (window ?? {}).ehApi = eh.EhApi.getInstance();

    }
  }

  //@ts-ignore
  export type Modernizr = {
    passiveeventlisteners: boolean
  };
  //@ts-ignore
  export const Modernizr: Modernizr = (window as any)?.Modernizr as Modernizr;

  //@ts-ignore
  export type CookieSameSite = 'None' | 'Strict' | 'Lax';
  //@ts-ignore
  export function setCookie(name: string, value: string, days: number, secure?: boolean, sameSite?: CookieSameSite):void {
    let expires = null;
    if (days > 0 || days === -1) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = date.toUTCString()
    }
    document.cookie = `${name}=${encodeURIComponent(value)}${sameSite ? `; SameSite=${sameSite}` : ''}${secure ? '; Secure' : ''}${expires ? `; expires=${expires}` : ''}; path=/`;
  }
  //@ts-ignore
  export function setGlobalCookie(name: string, value: string, days: number, secure?: boolean, sameSite?: CookieSameSite):void {
    let expires = null;
    if (days > 0 || days === -1) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = date.toUTCString()
    }
    let url = location.hostname;
    if(url.indexOf('.endress.') !== -1) {// endress.com.cn
      url = url.substring(url.indexOf('.endress.'));
      if (url.indexOf('/') !== -1) {
          url = url.split('/')[0];
      }
      document.cookie = `${name}=${encodeURIComponent(value)}${sameSite ? `; SameSite=${sameSite}` : ''}${secure ? '; Secure' : ''}${expires ? `; expires=${expires}` : ''}; domain=${url}; path=/`;
    } else {
      setCookie(name, value, days, secure, sameSite);
    }
  }
  //@ts-ignore
  export function getCookie(name: string):string {
    const result = new RegExp('(?:^|; )' + name + '=([^;]*)').exec(document.cookie);
    return result ? decodeURIComponent(result[1]) : '';
  }
  //@ts-ignore
  export function deleteCookie(name: string):void {
    setCookie(name, "", -1); 
  }

}


// Types of globally available extensions

// module: flowplayer
interface Flowplayer {
  isFullscreen: boolean;
  paused: boolean;
  fullscreen(): void;
  pause(): void;
}

// module: flowplayer
interface JQuery {
  flowplayer: any;
}

// module: jquery-form
interface JQuery {
  ajaxSubmit: (opts: {[key: string]: any}) => void;
  formSerialize: () => string;
}

// module: jquery-highlight
interface JQuery {
  highlight(term:string, options:{ [key: string]: string }):void;
}

// module: jquery-touchswipe
interface JQuery {
  swipe(obj: any): void;
}

// module: jquery-ui-datepicker
interface JQuery {
  datepicker: (opts: { [key: string]: any }) => void;
}

// module: jquery-ui-datepicker
interface JQueryStatic {
  datepicker: {
    _hideDatepicker(): void;
  };
}

// module: select2
declare module S2 {
  interface Utils {
    Decorate(classA: object, classB: object): object;
  }
}

// module: tealium
interface Window {
  utag: {
    link: () => void,
    view: () => void,
    gdpr: {
      showConsentPreferences: () => void
    }
  };
  utag_data: { [key: string]: string };
}

// module: picturefill
interface Window {
  picturefill: (opts: {[key: string]: any}) => void;
}

// module: browserDetect
interface Window {
  browserDetect(): any;
}

// module: aliyun-captcha
interface Window {
  noCaptcha: {
    new (opts: {[key: string]: any}): any;
  };
}

// public api nebp
interface Window {
  ehApi: eh.EhExternalApi
}
